<template>
	<div id="cpm_liveEdit" class="el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:8}">
			<a-form-item label="分类" >
				<a-select v-model:value="form.category_id" placeholder="请选择">
				    <a-select-option
						v-for="item in typeList"
						:key="item.id"
						:value="item.id">
						{{item.name}}
				    </a-select-option>
				  </a-select>
			</a-form-item>
			<a-form-item label="标题" >
				<a-input v-model:value="form.title"></a-input>
			</a-form-item>
			<a-form-item label="UID" >
				<template #label>
					<span>UID</span>
					<a-tooltip>
						<template #title>
							<div>
								<img  src="@/assets/img/live-uid.jpg" alt="" >
							</div>
						</template>
						<i class="el-icon-info"></i>
					</a-tooltip>
				</template>
				<a-input v-model:value="form.live_uid"></a-input>
			</a-form-item>
			<a-form-item label="封面"  class="cover-input">
				<kd-img-select :src="form.cover" @success="(url)=>{ form.cover = url }"></kd-img-select>
			</a-form-item>
			<a-form-item>
				<template #label>
					<span>播放类型</span>
					<a-tooltip title="监控类型为m3u8选择普通直播，rtmp类型选择实时直播，rtmp仅支持客户端,管理端仅支持m3u8格式播放">
						<i class="el-icon-info"></i>
					</a-tooltip>
				</template>
				<a-radio-group v-model:value="form.play_type">
					<a-radio :value="0">普通直播</a-radio>
					<a-radio :value="1">rtmp直播</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="选择经纬度">
				<kd-map :value="form.longitude+','+form.latitude" @change="getAddress"></kd-map>
			</a-form-item>
			<a-form-item label="是否显示"  >
				<a-radio-group v-model:value="form.open_status">
					<a-radio :value="0">不显示</a-radio>
					<a-radio :value="1">不限制</a-radio>
					<a-radio :value="2">订单展示</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="排序"  >
				<a-input v-model:value="form.rank"></a-input>
			</a-form-item>
			<a-form-item :wrapper-col="{offset:2}">
				<a-space>
					<a-button @click="submitSave" type="primary">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import liveModel from '@/api/live.js'
import store from '@/store'
export default {
	name:'page-live-edit',
	props:{
		is_shop:{
			type:Number
		}
	},
	setup(){
		const _d = reactive({
			form:{
				id:0,
				rank:99,
				title:'',
				cover:'',
				play_type:0,
				live_uid:'',
				open_status:1,
				category_id:'',
				latitude:null,
				longitude:null,
				address:'',
				province:"",
				city:""
			},
			typeList:[],
		})
		const opt = useRoute().query
		if( opt.id ) {
			liveModel.getLiveDetail(opt.id,Object.keys(_d.form),res=>_d.form = res)
		}
		liveModel.getLiveTypeList(1,999).then(res=>_d.typeList = res.list)
		function getAddress(e){
			_d.form.latitude = e.latitude
			_d.form.longitude = e.longitude
			_d.form.address = e.address
			_d.form.province = e.province
			_d.form.city = e.district
		}
		const submitSave = ()=>liveModel.addOrEditLive(_d.form)

		return{
			...toRefs(_d),
			submitSave,
			getAddress
		}
	}
}
</script>

<style>
</style>
